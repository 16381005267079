import { navigate } from "gatsby"
import React, { useState, useEffect } from "react"
import AllCourses from "../components/AllCourses"
import Profilepage from "../components/LandingPage/ProfilPage"
import Layout from "../components/layout"
import Spinner from "../components/Reusable/Spinner"
import SEO from "../components/seo"
import { getCoursesByTutor, getTutorBySlug } from "../services/course"

const TutorProfile = ({ pageContext }) => {
  const [tutor, setTutor] = useState({})
  const [courses, setCourses] = useState([])
  const [response, setResponse] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    getTutorBySlug(pageContext.slug)
      .then(resp => {
        setTutor(resp.data)
        setResponse(true)
      })
      .catch(error => {
        console.log(error.response.status)
        setError(error.response.status)
      })
  }, [pageContext.slug])

  useEffect(() => {
    if (!tutor?.id) return

    getCoursesByTutor(tutor?.user?.id)
      .then(resp => {
        const data = resp.data?.results
        setCourses(data)
      })
      .catch(error => {
        console.log(error)
      })
  }, [tutor])

  if (response)
    return (
      <Layout>
        <SEO title={tutor?.user?.display_name} />
        <Profilepage tutor={tutor} />
        <hr />
        {courses ? (
          <AllCourses courses={courses} />
        ) : (
          <h5 style={{ textAlign: "center" }}>Loading...</h5>
        )}
      </Layout>
    )

  if (response === false) {
    if (error === 404) navigate("/404")
    if (error === 500) navigate("/500")
  }

  return (
    <div
      style={{
        width: "100%",
        height: "90vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spinner />
    </div>
  )
}

export default TutorProfile
